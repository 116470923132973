:root {
    /* --primaryColor: #FAB015;   */
    --primaryColor: #ffc507;  
    --secondaryColor: #E45A00; 
    --hColor: #000;
    --pColor: #060000;  
}
html {
    scroll-behavior: smooth;
  }
body {    
    color: var(--pColor);
    font-size: 16px !important;
    font-weight: 400 !important; 
    line-height: 1.8 !important;
    letter-spacing: 0.15px;
    overflow-x: hidden !important;
    font-family: "Manrope", sans-serif !important;
    font-optical-sizing: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
}
body:before {
    position: absolute;
    content: "";
    left: 5%;
    top: 0;
    width: 80%;
    height: 100%;
    opacity: 1;
    background: url(../img/body-bg.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -1;
}
* {
    margin: 0;
    padding: 0;
}
a {
    text-decoration: none !important;
}
a:focus-visible {
    box-shadow: none !important;
}
h2 {
    font-size: 56px !important;
    color: var(--hColor);
}
.primaryBtn, .primaryBtn:focus {
    background: var(--primaryColor);
    color: var(--hColor);
    text-align: center;
    height: 42px;
    padding: 6px 30px 8px 30px;
    display: inline-block;
    border-radius: 10px;
    font-weight: 500;
    opacity: 1;
    transition: all ease-out .5s;
    position: relative;
}
.primaryBtn:hover:hover {
	opacity: 0.8;	
}
/* Navbar Start */
.navbar {
    padding: 20px 0 !important;
    background: transparent;
    transition: all .4s ease-in-out;
}
.navbar.active {
    padding: 10px 0 !important;
}
.navbar .dropdown.active a {
    color: var(--primaryColor);
}
.navbar .dropdown-menu a {
    color: var(--pColor) !important;
}
.navbar .container {
    transition: all .4s ease-in-out;
}
.navbar.active .container {
    border-radius: 18px;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    background: rgb(255 255 255 / 100%);
    box-shadow: 0px 5px 50px rgb(3 24 55 / 12%);
}
.navbar-brand img {
    max-width: 90%;
    transition: all .4s ease-in-out;
}
.navbar.active .navbar-brand img {
    max-width: 85%;
}
.navbar .navbar-brand #two {
    display:none;
    transition: all .4s ease-in-out;
}
.navbar.active .navbar-brand #one {
    display:none;
    transition: all .4s ease-in-out;
}
.navbar.active .navbar-brand #two {
    display: inherit;
    transition: all .4s ease-in-out;
}
.navbar-nav .nav-link, .navbar-nav .nav-link:focus {
    padding: 12px 10px;
    margin: 0 16px;
}
.navbar.active .navbar-nav .nav-link.active, .navbar.active .navbar-nav .nav-link:hover, .navbar.active .navbar-nav .nav-link.show {
    color: var(--primaryColor) !important;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link:hover, .navbar-nav .nav-link.show {
    color: var(--primaryColor) !important;
}
.navbar.active .navbar-nav .nav-link.active, .navbar.active .navbar-nav .nav-link:hover, .navbar.active .navbar-nav .nav-link.show {
    color: var(--primaryColor) !important;
}
.navbar .navbar-nav .nav-link.active, .navbar .navbar-nav .nav-link:hover, .navbar .navbar-nav .nav-link.show {
    color: var(--primaryColor) !important;
}
.navbar .navbar-nav .nav-link, .navbar-nav .nav-link:focus {
    color: #fff;
}
.dropdown-item {
    font-size: 15px;
}
.dropdown-menu {
    top: 50px !important;
}
.dropdown-menu a {
    color: var(--pColor);
    padding: 8px 26px;
    display: block;
    border-bottom: 1px solid #e2e0e0f7;
}
.dropend .dropdown-menu[data-bs-popper] {
    top: 0 !important;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: var(--bs-dropdown-spacer);
}
.serviceMenu {
    border-bottom: 1px solid #e2e0e0f7;
}
.serviceMenu a {
    color: var(--pColor) !important; 
    padding: 8px 52px 8px 26px !important; 
    margin: 0 !important;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;
    flex-wrap: nowrap;
    border-bottom: none;
}
.serviceMenu .dropdown-menu.show a {
    border-bottom: 1px solid #e2e0e0f7;
}
.serviceMenu .dropdown-menu.show a:last-child {
    border-bottom: none;
}
.dropend .dropdown-toggle::after {
    border-top: .35em solid transparent;
    border-bottom: .35em solid transparent;
    border-left: .35em solid;
}
.dropdown-menu a:last-child {
    border-bottom: none;
}
.dropdown-menu a:hover, .dropdown-menu a:focus {
    color: var(--hColor) !important;
    background-color: var(--bs-dropdown-link-hover-bg) !important;
}
.navbar .dropdown-menu {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    display: block; 
    visibility: hidden;
    border: none;
    box-shadow: 0px 5px 50px rgb(3 24 55 / 12%);
}

.navbar .dropdown-menu.show {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
}
.navbar-toggler, .navbar-toggler:focus {
    border: none !important;
    box-shadow: none !important;
}
.dropdown-item.active, .dropdown-item:active {
    color: var(--hColor) !important;
    text-decoration: none;
    background-color: #f8f9fa;
    background: inherit !important;
}
.navbar-toggler-icon {
    background-image: url('../img/navicon.svg') !important;
}
.navbar-toggler-icon {
    width: 1.35em !important;
    height: 1.35em !important;
}
.navbar-toggler {
    padding: 0 !important;
}
.navbar .primaryBtn {
    height: 42px;
    margin-top: 3px;
    margin-left: 40px;
    font-weight: 500;
}
.navmenuCenter {
    background: var(--hColor);
    border-radius: 18px;
    padding-left: 12px !important;
    padding-right: 12px !important;
}
.leftSocial {
    position: fixed;
    right: 2%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-end;
    align-items: center;
    justify-content: center;
    height: 50vh;
    width: 50px;
    z-index: 10;
}
.leftSocial img {
    width: 36px;
    height: 36px;
    margin: 5px 0;
    transition: all 0.3s ease-in-out;
}
.leftSocial a {
    position: relative;
    z-index: 1;
}
.leftSocial a:hover img {
    width: 36px;
    height: 36px;
    margin: 5px 0;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
}
.leftSocial h4 {
    opacity: .6;
    font-size: 18px;
    padding-right: 85px;
    transform: rotate(-90deg);
}
.whatsappIcon img {
    width: 55px !important;
    margin: 2px 0 !important;
}
/* Navbar End */
/* Home Banner Start */
.homeBanner {
    padding: 165px 0 75px 0;
    /* text-align: center; */
}
.homeBanner h1 .span1 {
    color: #CBCBCB !important;
}
.home-banner-text-animate {
    background-image: linear-gradient(-225deg, #0079C1 0%, #E54796 29%, #E45A00 67%, #aaa51c 100%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
  }
  
  @keyframes textclip {
    to {
      background-position: 200% center;
    }
  }
.homeBanner p {
    font-size: 17px;
    color: var(--hColor);
    font-weight: 500;
    margin-top: 30px;
    border-left: 3px solid #CBCBCB;
    padding-left: 20px;
}
.homeBanner h1 .span2 img {
    animation: wiggle 2s linear infinite;
}
@keyframes wiggle {
    0%, 7% {
      transform: rotateZ(0);
    }
    15% {
      transform: rotateZ(-15deg);
    }
    20% {
      transform: rotateZ(10deg);
    }
    25% {
      transform: rotateZ(-10deg);
    }
    30% {
      transform: rotateZ(6deg);
    }
    35% {
      transform: rotateZ(-4deg);
    }
    40%, 100% {
      transform: rotateZ(0);
    }
  }
  .homeCounter {
    padding-bottom: 95px;
  }
  .homeCounter .homeCounter-box {
    background-attachment: fixed;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .homeCounter hr {
    border-bottom: .5px solid #e5e9f1;
  }
  .homeCounter-box h3 {
    font-size: 48px;
    max-width: 90%;
    line-height: 1.35;
    font-weight: 600;
  }
  .homeCounter-counter {
    display: flex;
    justify-content: space-between;
  }
  .homeCounter-box1 {
    padding-bottom: 10px;
  }
  .homeCounter-box1 p {
    padding: 20px 0;
  }
  .homeCounter-box2 {
    padding-top: 15px;
  }
  .homeCounter-box2 h4 {
    font-size: 46px;
    font-weight: 700;
    color: var(--secondaryColor);
  }
  .homeCounter-plus {
    font-size: 40px;
    color: var(--pColor);
    padding-left: 5px;
  }
  .homeCounter-title {
    display: block;
    font-size: 18px;
    font-weight: 500;
    color: var(--hColor);
  }
  .homeService .homeService-img {
    background-image: url(../img/why-choose-bg.jpg);
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 26px;
    padding: 5%;
    position: relative;
    z-index: 1;
  }
  .homeService-img::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 26px;
    background: rgb(0 0 0 / 85%);
    z-index: -1;
  }
  .homeService-img h2 {
    color: #fff !important;
    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid #3e3e3e;
  } 
  .homeService-img-text {
    max-width: 95%;
    padding: 18px 10px;
    border-bottom: 1px solid rgb(254 254 254 / 17%);
  } 
  .homeService-img-text:last-child {
    border-bottom: none;
    padding-bottom: 0px;
  }
  .homeService-img-text h4 {
    font-size: 26px;
    padding: 10px 0;
    color: var(--primaryColor);
  }
  .homeService-img-text h4 span {
    color: var(--primaryColor);
    opacity: .35;
    padding-right: 5px;
    font-size: 38px;
  }
  .homeService-img-text p {
    /* max-width: 80%; */
    color: #D9D9D9 !important;
  }
  .homeService-box img {
    border-radius: 20px;
  }
  .homeProjects {
    padding: 80px 0;
  }
  .homeProjects-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
  }
  .homeProjects-box { 
    padding: 0 15px;
  }
  .homeProjects-box .img1 { 
    width: 100%;
    height: 360px;
    object-fit: cover;
    border-radius: 20px;
    object-position: center;
  }
  .homeProjects-text { 
    display: flex;
  }
  .homeProjects-text h4 { 
    font-size: 26px;
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 6px;
  }
  .homeProjects-text p { 
    padding-bottom: 6px;
    max-width: 90%;
  }  
  .homeProjects-text a { 
    transition: all ease-out .5s;
  }
  .homeProjects-text a:hover { 
    opacity: .8;
  }
  .homeProjects-text .img2 {
    position: relative;
    top: 30%;
    width: 100%;
  }  
.homeProjects .slick-prev, .homeProjects .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 28%;
}
.slick-prev:before, .slick-next:before {
    content: '' !important;
}
.slick-arrow {
    z-index: 10000;
}
.homeReal-box {
    background: var(--primaryColor);
    margin-bottom: 88px;
    border-radius: 26px;
    padding: 45px;
    position: relative;
}
.homeReal-box h2 {
    font-size: 44px !important;
    padding-left: 2%;
    line-height: 1.5;
    font-weight: 600;
    color: var(--pColor);
}
.homeReal-box .span1 {
    display: block;
}
.homeReal-box .span2 {
    display: block;
}
.homeReal-box .span3 {
    display: block;
}
.homeReal-box .span4 {
    color: var(--secondaryColor);
}
.homeReal-box .span4 span {
    color: var(--hColor);
}
.homeReal-box:before {
    position: absolute;
    content: "";
    right: 0;
    top: 10%;
    width: 40%;
    height: 75%;
    opacity: 1;
    background: url(../img/bg-logo-2.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: 1;
}
.homeTime-box {
    display: flex;
    align-items: center;
}
.homeTime-box h4 {
    font-size: 64px;
    font-weight: 400;
    margin-right: 25px;    
}
.homeTime-text h5 {
    font-size: 26px;
    color: var(--secondaryColor);
}
.homeTime-text p {
    max-width: 85%;
}
.homeConnect-box {
    position: relative;
    margin-top: 25px;
    margin-bottom: 88px;
    border-radius: 26px;
    padding: 70px 75px;
    background: var(--primaryColor);
}
.homeConnect-box:before {
    position: absolute;
    content: "";
    left: -12%;
    top: 5%;
    width: 50%;
    height: 65%;
    opacity: 1;
    background: url(../img/bg-logo-2.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: 0;
}
.homeConnect-box h2 {
    position: relative;
    line-height: 1.5;
    font-size: 44px !important;
    z-index: 1;
}
.homeConnect-link {
    height: 100%;
    display: flex;
    justify-content: center;
}
.homeConnect-box img {
    width: 30%;
    animation: wiggle 2s linear infinite;
}
.homeConnect-box img:hover {
    opacity: .8;
}
.homeCategory {
    padding: 165px 0 65px;
}
.homeCategory h4 {
    border-bottom: 1px solid #bec2c9;
    color: var(--secondaryColor);
    padding-bottom: 6px;
    font-size: 22px;
    font-weight: 600;
}
.homeCategory-box1 {
    padding: 22px 30px 30px 30px;
    border-radius: 25px;
    background: #E5E9F1;
    margin-bottom: 25px;
    display: block;
}
.homeCategory-box1 p {
    line-height: 1.5;
    margin-bottom: 10px;
    color: var(--pColor);
}
.homeCategory-box1 img {
    height: 150px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    transition: transform .5s ease;
}
.homeCategory-box1:hover img {
    opacity: 0.8;
}
.homeCategory-box2 {
    padding: 36px 40px;
    border-radius: 25px;
    background: var(--primaryColor);
    margin-bottom: 25px;
}
.homeCategory-box2 h1 {
    font-size: 46px;
    font-weight: 700;
    padding-bottom: 20px;
    border-bottom: 1px solid #ffdc92;
}
.homeCategory-box2 h1 .span1 {
    display: block;
}
.homeCategory-box2 h1 .span2 {
    display: block;
    padding-right: 15px;
}
.homeCategory-box2 p {
    margin-bottom: 0;
    padding-top: 6px;
    font-weight: 500;
}
.homeCategory-box3 {
    padding: 22px 30px 30px 30px;
    border-radius: 25px;
    background: #E5E9F1;
    margin-bottom: 25px;
    display: block;
}
.clientlogo {
    padding-bottom: 85px;
}
.clientlogo-box {
    padding: 20px 0;
    
}
.clientlogo-box img {
    max-width: 90%;
    padding: 20px;
    border-radius: 18px;
    /* border: 1px solid #e5e9f1; */
    background: rgb(229 233 241 / 36%);
}
.clientlogo .slick-prev, .clientlogo .slick-next {
    top: 40% !important;
}
/* Home Banner End */
/* Footer Start */
footer {
    background: #000;
}
.footerLink {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0;
    border-bottom: 1px solid #1f1e1e;
}
.footerLink a, .footerLink a:focus {
    color: #D9D9D9;
    transition: all ease-out .5s;
}
.footerLink a:hover {
    color: var(--primaryColor);
}
.footerMail {
    color: #fff;
    text-align: end;
    padding: 12px 0;
    border-bottom: 1px solid #1f1e1e;
}
.footerMail a {
    color: #fff;
    font-size: 30px; 
    transition: all ease-out .5s;   
}
.footerMail a:hover {
    color: var(--secondaryColor);
}
.footerAddress {
    padding: 40px 0;
}
.footerAddress h4 {
    color: #fff;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 25px;
}
.footerAddress p {
    color: #D9D9D9;
    margin-bottom: 5px;
}
.footerAddress p span {
    color: #fff;
}
.footerAddress a {
    color: #D9D9D9;
    transition: all ease-out .5s;
}
.footerAddress a:hover {
    color: var(--primaryColor);
}
.footerMail-id {
    padding: 40px 0;
}
.footerMail-id h4 {
    color: #fff;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 25px;
}
.footerMail-id p {
    color: #D9D9D9;
    margin-bottom: 5px;
}
.footerMail-id p span {
    color: #fff;
}
.footerMail-id a {
    color: #D9D9D9;
    transition: all ease-out .5s;
}
.footerMail-id a:hover {
    color: var(--primaryColor);
}
.footerLogo {
    text-align: end;
    padding: 40px 0;
}
.footerLogo img {
    width: 42%;
}
.footerSocial {
    text-align: end;
    padding: 40px 0;
}
.footerSocial p {
    margin-bottom: 4px;
    font-size: 18px;
}
.footerSocial a {
    color: #D9D9D9;
    transition: all ease-out .5s;
}
.footerSocial a:hover {
    color: var(--primaryColor);
}
.footerCopy {
    color: #D9D9D9;
    padding-top: 14px;
    border-top: 1px solid #1f1e1e;
}
.footerCopy p {
    font-size: 13px !important;
}
.footerTerms {
    display: flex;
    justify-content: flex-end;
    padding-top: 12px;
    border-top: 1px solid #1f1e1e;
}
.footerTerms a {
    color: #D9D9D9;  
    margin-left: 25px;  
    font-size: 13px !important;
    transition: all ease-out .5s;
}
.footerTerms a:hover {
    color: var(--primaryColor);
}
.leftWhatsapp {
    position: fixed;
    right: 2%;
    bottom: 6%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-end;
    align-items: center;
    justify-content: center;
    z-index: 10;
}
/* Footer End */
/* About Start */
.aboutSec {
    padding: 158px 0 45px 0;
}
.aboutSec h1 {
    color: var(--hColor);
    font-size: 42px;
    line-height: 1.35;
    font-weight: 600;
}
.aboutSec-img {
    text-align: center;
}
.aboutSec-img img {
    width: 100%;
    height: 300px;
    border-radius: 25px;
    object-fit: cover;
    object-position: center;
    margin: 15px 0;
}
.aboutSec-vision {
    padding: 65px 0 70px 0;
}
.aboutSec-vision h3 {
    color: var(--hColor);
    padding-bottom: 10px;
    margin-bottom: 25px;
    font-weight: 600;
    border-bottom: 2px solid var(--primaryColor);
}
/* About End */
/* Services Start */
.brandServices {
    padding: 158px 0 50px 0;
}
.brandServices h1 {
    color: var(--hColor);
    font-size: 42px;
    line-height: 1.35;
    font-weight: 600;
}
.servicesBanner-box img {
    border-radius: 25px;
    margin-bottom: 25px;
    width: 100%;
    height: 325px;
    object-fit: cover;
    object-position: center;
}
.brandServices-one {
    padding: 60px 0 15px 0;
}
.brandServices-one h4 {
    color: var(--secondaryColor);
    padding-bottom: 8px;
    font-weight: 700;
    border-bottom: 2px solid #eee;
    margin-bottom: 20px;
}
.brandServices-one ul {
    margin-left: -12px;
}
.brandServices-one ul li {
    padding-bottom: 5px;
}
.brandServices-one img {
    border-radius: 25px;
}
.brandServices-two {
    padding: 70px 0;
}
.brandServices-two img {
    border-radius: 25px;
}
.brandServices-two h3 {
    font-size: 34px;
    font-weight: 600;
    padding: 15px 0;
}
.caseStudies {
    padding-bottom: 80px;
}
.caseStudies h3 {
    font-size: 34px;
    font-weight: 600;
    padding-bottom: 15px;
}
.caseStudies img {
    border-radius: 15px;
}
.outdoorServices {
    padding: 158px 0 50px 0;
}
.outdoorServices h1 {
    color: var(--hColor);
    font-size: 42px;
    line-height: 1.35;
    font-weight: 600;
}
.outdoorServices-one-box {
    padding: 60px 0;
    max-width: 96%;
}
.outdoorServices-one-box img {
    width: 100%;
    height: 300px;
    border-radius: 25px;
    object-fit: cover;
    object-position: center;
    margin: 15px 0;
}
.outdoorServices-one-box h4 {
    color: var(--secondaryColor);
    font-weight: 600;
    padding-bottom: 10px;
    padding-top: 25px;
    font-size: 26px;
}
.outdoorServices-two-box {
    padding: 70px 0;
    max-width: 96%;
    border-top: 2px solid #e5e9f1;
}
.outdoorServices-two-box img {
    width: 100%;
    height: 300px;
    border-radius: 25px;
    object-fit: cover;
    object-position: center;
    margin: 15px 0;
}
.outdoorServices-two-box h4 {
    color: var(--secondaryColor);
    font-weight: 600;
    padding-bottom: 10px;
    padding-top: 25px;
    font-size: 26px;
}
.printingServices {
    padding: 158px 0 50px 0;
}
.printingServices h1 {
    color: var(--hColor);
    font-size: 42px;
    line-height: 1.35;
    font-weight: 600;
}
.printingServices-one-box {
    padding: 6px 0 70px 0;
}
.printingServices-one-box-img1 img {
    border-radius: 25px;
    margin-bottom: 25px;
    width: 100%;
    height: 325px;
    object-fit: cover;
    object-position: center;
}
.printingServices-one-box-img2 img {
    width: 100%;
    height: 88%;
    object-fit: cover;
    object-position: center;
    border-radius: 25px;
    object-fit: cover;
    object-position: center;
    margin: 15px 0;
}
.printingServices-one-box h2 {
    color: var(--hColor);
    font-size: 40px !important;
    padding-bottom: 6px;
}
.printingServices-one-box h4 {
    padding-top: 12px;
    padding-bottom: 6px;
    color: var(--hColor);
}
.printingServices-one-box h4 span {
    color: var(--primaryColor);
    padding-right: 5px;
}
.printingServices-one-box-text {
    border-bottom: 1px solid #d9d9d9;
    margin-left: 2%;
    max-width: 95%;
    padding: 20px 10px;
}
.printingServices-one-box-text h4 {
    font-size: 26px;
    padding: 10px 0;
    color: var(--hColor);
    font-weight: 500;
}
.printingServices-one-box-text p {
    max-width: 95%;
}
.eventsServices-one {
    padding-top: 55px;
}
.eventsServices-one h3 {
    max-width: 95%;
    padding-top: 20px;
    padding-bottom: 6px;
    color: var(--secondaryColor);
    font-size: 32px;
    font-weight: 600;
}
.eventsServices-one p {
    padding-bottom: 15px;
    max-width: 95%;
}
.eventsServices-one img {
    border-radius: 25px;
    margin-bottom: 15px;
    width: 100%;
    height: 325px;
    object-fit: cover;
    object-position: center;
}
.eventsServices-two {
    padding-top: 60px;
}
.eventsServices-two-box {
    padding: 70px 0;
    border-top: 2px solid #e5e9f1;
}
.eventsServices-two h3 {
    max-width: 95%;
    padding-top: 30px;
    padding-bottom: 6px;
    color: var(--secondaryColor);
    font-size: 32px;
    font-weight: 600;
}
.eventsServices-two p {
    padding-bottom: 15px;
    max-width: 95%;
}
.eventsServices-two img {
    border-radius: 25px;
    width: 100%;
    height: 325px;
    object-fit: cover;
    object-position: center;
}
/* Services End */
/* Project Start */
.projectSec {
    padding: 158px 0 45px 0;
}
.projectSec h1 {
    color: var(--hColor);
    font-size: 42px;
    line-height: 1.35;
    font-weight: 600;
}
.projectTab {
    padding-bottom: 85px;
}
.projectTab .nav-tabs {
    padding-bottom: 10px;
}
.projectTab .nav-tabs .nav-link {
    background: rgb(190 194 201 / 23%) !important;
    color: var(--hColor) !important;
    text-align: center;
    height: 42px;
    margin-right: 15px;
    padding: 6px 30px 8px 30px;
    display: inline-block;
    border-radius: 10px;
    border: none;
    font-weight: 500;
    opacity: 1;
    transition: all ease-out .5s;
    position: relative;
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color);
}
.projectTab .nav-tabs .nav-item.show .nav-link, .projectTab .nav-tabs .nav-link.active {
    background: var(--primaryColor) !important;
    color: var(--hColor) !important;
    text-align: center;
    height: 42px;
    padding: 6px 30px 8px 30px;
    display: inline-block;
    border-radius: 10px;
    border: none;
    font-weight: 500;
    opacity: 1;
    transition: all ease-out .5s;
    position: relative;
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color);
}
.projectTab img {
    margin-top: 22px;
    border-radius: 15px;
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: center;
}
.projectTab-box:hover {
    cursor: pointer;
}
.projectTab-box h4 {
    color: var(--hColor);
    padding-top: 15px;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 12px;
    border-bottom: 2px solid #eeeef2;
}
.projectTab-box:hover h4 {
    color: var(--primaryColor);
}
.projectTab-box:hover img {
    opacity: 0.8;
}
/* Project End */
/* Client Start */
.clientSec {
    padding: 158px 0 35px 0;
}
.clientSec h1 {
    color: var(--hColor);
    font-size: 42px;
    line-height: 1.35;
    font-weight: 600;
    padding-bottom: 15px;
}
.clientlogoSec {
    padding-bottom: 85px;
}
.clientlogoSec img {
    max-width: 100%;
    padding: 20px;
    margin: 12px 0;
    border-radius: 18px;
    background: rgb(229 233 241 / 36%);
}
.clientlogoSec img:hover {
    opacity: 0.65;
}
/* Client End */
/* Contact Start */
.contactSec {
    padding: 165px 0 45px 0;
}
.contactSec img {
    width: 100%;
    height: 300px;
    border-radius: 25px;
    object-fit: cover;
    object-position: center;
    margin: 15px 0;
}
.contactForm {
    padding-bottom: 75px;
}
.contactForm h2 {
    font-size: 46px !important;
    color: var(--hColor);
    line-height: 1.35;
    font-weight: 600;
}
.contactForm h2 span {
    color: var(--secondaryColor);
}
.contactForm-address {
    margin: 20px 0;
}
.contactForm-address h4 {
    color: var(--secondaryColor);
    padding-top: 20px;
    padding-bottom: 10px;
}
.contactForm-address p {
    color: var(--hColor);
    margin-bottom: 6px;
    font-size: 18px;
}
.contactForm-address p span {
    opacity: 0.6;
    color: var(--pColor);
    font-weight: 600;
    font-size: 16px;
}
.contactForm-address a {
    color: var(--pColor);
}
.contactForm-address a:hover {
    color: var(--primaryColor);
}
.contactForm form {
    margin: 20px 0;
    padding: 6.5% 6%;
    border-radius: 26px;
    background: #E5E9F1;
}
.contactForm form button {
    text-align: right;
}
.contactForm .form-control {
    height: 45px;
}
.contactForm textarea {
    height: auto !important;
}
.contactForm textarea:focus, .contactForm .form-control:focus {
    box-shadow: none;
    border: 1px solid var(--primaryColor);
}
.contactForm .primaryBtn {
    background: var(--primaryColor);
    color: var(--hColor);
    text-align: center;
    height: 42px;
    padding: 6px 30px 8px 30px;
    display: inline-block;
    border-radius: 10px;
    font-weight: 500;
    opacity: 1;
    transition: all ease-out .5s;
    position: relative;
    border: none;
}
.contactForm .primaryBtn:hover:hover {
    opacity: 0.8;
    background: var(--primaryColor);
    color: var(--hColor);
}
/* Contact End */
@media screen and (max-width: 480px) {    
    .navbar .dropdown-menu {
        display: none; 
    }
    .navbar-nav .dropdown-menu {
        position: initial !important;
    }
    .navbar.active .container {
        margin-top: 18px;        
    }
    .navbar.active .container .collapse.show {
        padding-bottom: 18px !important;
    }
    .navbar {
        background: #fff;
    }
    .navbar.active .collapse {
        background: transparent;
    }
    .navmenuCenter {
        padding-left: 8px !important;
        padding-right: 8px !important;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 8px;
        margin-bottom: 20px !important;
    }
    h1 {
        font-size: 36px !important;
        padding-bottom: 10px;
    }
    h2 {
        font-size: 44px !important;
    }
    .homeBanner {
        padding: 145px 0 50px 0;
    }
    .homeBanner h1 .span1 {
        font-size: 36px !important;
    }
    .homeBanner h1 .span2 img {
        width: 60px;
    }
    .homeBanner p {
        font-size: 16px;
    }    
    .leftSocial {
        display: none;
    }
    .homeCounter-box h3 {
        font-size: 36px;
    }
    .homeCounter-counter {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .homeCounter-box2 h4 {
        font-size: 32px;
    }
    .homeProjects .slick-prev img {
        display: block;
        left: 30px;
        background: var(--primaryColor);
        height: 45px;
        width: 45px;
        padding: 3px;
        border-radius: 6px;
        position: relative;
    }
    .homeProjects .slick-next img {
        display: block;
        right: 30px;
        background: var(--primaryColor);
        height: 45px;
        width: 45px;
        padding: 3px;
        border-radius: 6px;
        position: relative;
    }
    .homeReal-box h2 {
        padding-left: 0;
        line-height: 1.75;
        font-size: 24px !important;
    }
    .homeTime-text p {
        max-width: 100%;
    }
    .homeTeam .slick-prev img {
        display: block;
        left: 30px;
        background: var(--primaryColor);
        height: 45px;
        width: 45px;
        padding: 3px;
        border-radius: 6px;
        position: relative;
    }
    .homeTeam .slick-next img {
        display: block;
        right: 30px;
        background: var(--primaryColor);
        height: 45px;
        width: 45px;
        padding: 3px;
        border-radius: 6px;
        position: relative;
    }
    .homeConnect-box:before {
        left: 0;
    }
    .homeConnect-box {
        padding: 40px;
    }
    .homeConnect-box img {
        width: 20%;
        margin-top: 10px;
    }
    .homeConnect-box h2 {
        font-size: 26px !important;
    }
    .footerLink {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .footerLink a {
        margin: 5px 10px;
    }
    .homeProjects-title {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .footerLogo {
        text-align: left;
        padding: 20px 0;
    }
    .footerLogo img {
        width: 22%;
    }
    .footerSocial {
        text-align: left;
    }
    .homeCategory-box2 h1 {
        font-size: 40px !important;
    }
    .homeCategory-box3 img {
        height: 150px;
    }
    .homeService .homeService-img {
        padding: 18% 5%;
    }
    .contactForm h2 {
        font-size: 32px !important;
    }
    .projectTab .nav-tabs .nav-link {
        margin-bottom: 10px !important;
    }
    .footerMail a {
        font-size: 24px;
    }
    .footerAddress h4 {
        font-size: 22px;
    }
    .brandServices-one img {
        margin-bottom: 30px;
    }
    .brandServices-one {
        padding: 60px 0 0 0;
    }
    .brandServices-two {
        padding: 55px 0;
    }
    .brandServices-two img {
        border-radius: 25px;
        margin-bottom: 20px;
        width: 100%;
    }
    .eventsServices-one {
        padding-top: 35px;
    }
    .eventsServices-two h3 {
        padding-top: 0px;
    }
}
@media only screen and (min-device-width: 600px) and (max-device-width: 696px) {
    .brandServices-one {
        padding: 50px 0 0 0;
    }
    .brandServices-one img {
        margin-bottom: 30px !important;
    }
    .brandServices-two img {
        border-radius: 25px;
        width: 100%;
        margin-bottom: 30px;
    }
    .eventsServices-one {
        padding-top: 35px;
    }
    .eventsServices-two h3 {
        padding-top: 0px;
    }
    .eventsServices-two-box {
        padding: 45px 0 80px 0;
    }
}
@media only screen and (min-device-width: 698px) and (max-device-width: 798px) {
    .navbar .dropdown-menu {
        display: none; 
    }
    .navbar-nav .dropdown-menu {
        position: initial !important;
    }
    .navbar.active .container {
        margin-top: 18px;        
    }
    .navbar.active .container .collapse.show {
        padding-bottom: 18px !important;
    }
    .navbar {
        background: #fff;
    }
    .navbar.active .collapse {
        background: transparent;
    }
    .navmenuCenter {
        padding-left: 8px !important;
        padding-right: 8px !important;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 8px;
        margin-bottom: 20px !important;
    }
    h1 {
        font-size: 30px !important;
    }
    h2 {
        font-size: 44px !important;
    }
    .homeBanner h1 .span1 {
        font-size: 62px !important;
    }
    .homeBanner h1 .span2 img {
        width: 60px;
    }
    .homeBanner p {
        font-size: 16px;
    }
    .leftSocial {
        right: -8%;
    }
    .homeService-img-text p {
        max-width: 100%;
    }
    .homeService-img-text {
        max-width: 100%;
    }
    .homeService-img-text h4 {
        font-size: 24px;
    }
    .homeProjects .slick-prev img {
        display: block;
        left: 30px;
        background: var(--primaryColor);
        height: 45px;
        width: 45px;
        padding: 3px;
        border-radius: 6px;
        position: relative;
    }
    .homeProjects .slick-next img {
        display: block;
        right: 30px;
        background: var(--primaryColor);
        height: 45px;
        width: 45px;
        padding: 3px;
        border-radius: 6px;
        position: relative;
    }
    .homeConnect-box h2 {
        font-size: 40px !important;
    }
    .homeConnect-box:before {
        position: absolute;
        content: "";
        left: 4%;
        top: 0;
    }
    .homeConnect-box img {
        width: 16%;
    }   
    .homeCategory-box3 img {
        height: 200px;
        object-fit: cover;
        object-position: center;
    } 
    .homeService .homeService-img {
        padding: 10% 5%;
    }
    .contactForm h2 {
        font-size: 40px !important;
    }    
}
@media only screen and (min-device-width: 981px) and (max-device-width: 1024px) {
    .navbar-nav .nav-link, .navbar-nav .nav-link:focus {
        font-size: 15px;
        padding: 12px 8px !important;
        margin: 0 5px;
    }
    .navbar-brand img {
        max-width: 75%;
        transition: all .4s ease-in-out;
    }
}